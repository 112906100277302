.signin {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.signin > form {
        display: grid;
        flex-direction: column;
}

        .signin > form > input {
            margin-bottom: 5px;
        }

.signin__link:hover {
    text-decoration: underline;
}
